import React from 'react';
import OnlinePaymentProcessMPGS from "../../components/online-payment/mpgs/online-payment-process-mpgs";

const OnlinePaymentProcessMpgsPage = () => {
    return (
        <div>
            <OnlinePaymentProcessMPGS/>
        </div>
    )
}

export default OnlinePaymentProcessMpgsPage;
