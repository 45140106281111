import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import '../../../assets/styles/payment.css';
import QbeLogo from '../../../assets/QBE-logo-on-light.png';
import useConfig from "../../qnect-ui/qnect-config";
import {_initiateOnlineMastercardPayment} from "../../../store/policy-payment/service";

import {
    _getCountry, _getLang, _getPaymentToken,
    _hideSpinner, _setLang,
    _showError,
    _showSpinner, _storePaymentToken
} from "../../../store/system/service";
import {
    _onlineAcceptPayment,
    _onlineFailPayment,
    _getPolicyPayment,
    _storePolicyPayment
} from "../../../store/policy-payment/service";
import {_formatPaymentAmount, _getPolPaymentCurrency, _sendLog} from "../../../utils/utility";
import * as TagManager from "react-gtm-module";
import {_resetPolicy} from "../../../store/policy/service";

const OnlinePaymentProcessMPGS = (props) => {
    const cfg = useConfig(props.lang);


    const formRef = useRef(null);
    const [lang, setLang] = useState( props.lang);
    const [productName] = useState (props.policyPayment.productName);
    const [policyNumber] = useState (props.policyPayment.policyNumber);
    const [country] = useState (props.policyPayment.country);
    const [totalAmount] = useState(props.policyPayment.amount);

    const originalConsoleError = console.error;
    console.error = function () {
        const errorArguments = Array.from(arguments);
        const errorMessage = errorArguments.map(arg => (typeof arg === 'object' ? JSON.stringify(arg) : arg)).join(" ");
        _sendLog("online-payment-process-mpgs.js: " + errorMessage, "error")
        originalConsoleError.apply(console, arguments);
    }

    const [policyPaymentInitiated] = useState(sessionStorage.getItem('policyPaymentInitiated'));
    const [policyPaymentSuccess] = useState(sessionStorage.getItem('policyPaymentSuccess'));

    useEffect(()=>{
        sessionStorage.setItem('policyPaymentInitiated', policyPaymentInitiated)
    },[policyPaymentInitiated]);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Online Payment Process – Macau - MPGS Payment - ' + productName,
                vPath: '/mpgs-online-payment-process',
                vProduct: productName,
                vPolicyNo: policyNumber,
                vPaymentCurrency: _getPolPaymentCurrency(country),
                vPaymentAmount: totalAmount,
                vCountry: country,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: 1
                        }
                    }
                }
            }
        });

        if (!(policyPaymentInitiated === 'true')) {
            initiateMasterCardPayment();
        } else {
            props.storePolicyPayment(JSON.parse(sessionStorage.activePolicyPayment));
            const paymentTokenJson = JSON.parse(sessionStorage.activePaymentToken);
            props.storePaymentToken(paymentTokenJson?.token, paymentTokenJson?.vector);

            if(policyPaymentSuccess === 'true') {
                props.history.push('/payment-finish');
                return;
            }
            sessionStorage.setItem('policyPaymentInitiated', 'true');
            window.Checkout.configure({
                session: {
                    id: props.policyPayment.paymentMastercardSessionId
                }
            });
            window.Checkout.showEmbeddedPage('#embed-target');
            console.log('initiateMastercardPayment called : session Id : ' + props.policyPayment.paymentMastercardSessionId);
        };
    }, []);

    function initiateMasterCardPayment(){

        console.log("initiateMastercardPayment call ");

        props.showSpinner();
        props.initiateMastercardPayment(props.policyPayment, props.paymentToken).then(resp => {
            props.storePolicyPayment(resp);
            props.hideSpinner();
            window.Checkout.configure({
                session: {
                    id: resp.payment.paymentMastercardSessionId
                }
            });
            sessionStorage.setItem('activePolicyPayment', JSON.stringify(resp));
            sessionStorage.setItem('activePaymentToken', JSON.stringify(props.paymentToken));
            sessionStorage.setItem('policyPaymentInitiated', 'true');
            console.log('initiateMastercardPayment called : session Id : ' + resp.payment.paymentMastercardSessionId);
            props.storePolicyPayment(resp);
            window.Checkout.showEmbeddedPage('#embed-target');

        }, (error) => {
            console.error("initiateMastercardPayment response " , error?.response);
            if (error?.data?.errorMessage) {
                props.showError([error.data.errorMessage]);
            } else {
                props.showError([error.response?.data]);
            }
            _sendLog(' online-payment-process-mpgs.js - initiateMasterCardPayment() - exception:\n\n' + JSON.stringify(error));
            props.hideSpinner();
        })
    }

    window.errorCallbackMCPG = function(error) {
        console.log(JSON.stringify(error));

        _sendLog(['online-payment-process-mpgs.js - errorCallbackMCPG - Error from the master card payment gateway:'+props.policyPayment.policyNumber+' ', error], 'error');
        let errorMessage = error.message + '&lt;br&gt;' + 'Transaction number: ' + error.referenceNumber;

        props.hideSpinner();
        props.showError([errorMessage]);
        _sendLog(' online-payment-process-mpgs.js - errorCallbackMCPG(): errorMessage = ' + errorMessage, 'info');

        props.failPayment(props.policyPayment, props.paymentToken).then(resp => {
            console.log(resp);
            _sendLog(' online-payment-process-mpgs.js - paymentError() - failPayment(): resp =\n\n' + JSON.stringify(resp), 'info');
        }, errorMessage => {
            console.error(errorMessage);
            _sendLog(' online-payment-process-mpgs.js- paymentError() - failPayment(): exception = ' + errorMessage);
        })
    }

    window.completeCallbackMCPG = function(response) {
        console.log('Mastercard Gateway Payment complete. Response:' , response);

        _sendLog('OnlinePayment-MAC-React- completeCallbackMCPG - Payment successful next calling issueAndReceiptMastercardGatewayPayment. Reference Number '
            + props.policyPayment.payment.paymentReferenceNumber + ' paymentMastercardSuccessToken :' + props.policyPayment.payment.paymentMastercardSuccessToken  + " policyNumber:" + props.policyPayment.policyNumber, 'info');

        sessionStorage.setItem('policyPaymentSuccess', 'true');
        props.policyPayment.payment.paymentToken = response.resultIndicator;


        props.showSpinner();

        paymentSuccess(props.policyPayment.payment.paymentReferenceNumberToken, props.policyPayment.payment.paymentReferenceNumber,
            response.authCode, props.policyPayment.payment.paymentReferenceNumber,
            response.email, response.resultIndicator, response.cardExpiryDate, response.cardNumber,
            response.forename, response.surname, response.transactionType);
    }

    const paymentSuccess = (referenceNumberToken, referenceNumber, auth_code, receiptNumber,
                            email, paymentToken, cardExpiryDate, cardNumber, forename, surname, transactionType) => {
        console.log('Payment success, refNumber ' + referenceNumber + ' receiptNumber:' + receiptNumber);
        _sendLog(' online-payment-process.js - paymentSuccess(): referenceNumber = '+ referenceNumber + ', auth_code = '+ auth_code + ', receiptNumber = ' + receiptNumber, 'info');

        props.policyPayment.payment = {
            paymentReferenceNumber: referenceNumber,
            paymentReferenceNumberToken: referenceNumberToken,
            auth_code: auth_code,
            receiptNumber: receiptNumber,
            email: email,
            paymentToken: paymentToken,
            cardExpiryDate: cardExpiryDate,
            cardNumber: cardNumber,
            forename: forename,
            surname: surname,
            transactionType: transactionType
        };

        Object.keys(props.policyPayment.payment).forEach(function (key) {
            if(typeof props.policyPayment.payment[key] === 'undefined'){
                delete props.policyPayment.payment[key];
            }
        });

        _sendLog(' online-payment-process.js - paymentSuccess(): calling acceptPayment()...', 'info');
        props.acceptPayment(props.policyPayment, props.paymentToken).then(resp => {
            console.log(resp);
            _sendLog(' online-payment-process.js - paymentSuccess() - acceptPayment(): resp =\n\n' + JSON.stringify(resp), 'info');
            props.hideSpinner();
            props.storePolicyPayment(resp);
            props.resetPolicy();
            props.history.push('/payment-finish');
        }, (error) => {
            props.hideSpinner();
            if (error?.data?.errorMessage) {
                props.showError([error.data.errorMessage]);
            } else {
                props.showError([error.response?.data]);
            }
            _sendLog(' online-payment-process.js - paymentSuccess() - acceptPayment(): exception:\n\n' + JSON.stringify(error));
        });
    }

    const paymentError = (message, invalidFields, reasonCode, referenceNumber) => {
        console.log('Payment error message ' + message + ' invalidFields:' + invalidFields + ' reasonCode ' + reasonCode);
        _sendLog(' online-payment-process.js - paymentError(): message = ' + message + ', invalidFields = ' + invalidFields + ', reasonCode = ' + reasonCode);
        let errorMessage = message + '&lt;br&gt;' + 'Transaction number: ' + referenceNumber;

        props.hideSpinner();
        props.showError([errorMessage]);
        _sendLog(' online-payment-process.js - paymentError(): errorMessage = ' + errorMessage, 'info');

        props.failPayment(props.policyPayment, props.paymentToken).then(resp => {
            console.log(resp);
            _sendLog(' online-payment-process.js - paymentError() - failPayment(): resp =\n\n' + JSON.stringify(resp), 'info');
        }, errorMessage => {
            console.error(errorMessage);
            _sendLog(' online-payment-process.js - paymentError() - failPayment(): exception = ' + errorMessage);
        })
    }

    const switchLanguage = () => {
        let newLang = lang === 'en' ? 'zh' : 'en';
        setLang(newLang);
        props.setLang(newLang);
    }

    return (
        <div id="online-payment-credit-card-details-page" className="pageContainer online-payment-payment-page">
            <div className="container">
                <div className="topRow">
                    <div className="left">
                        <a href="https://www.qbe.com/hk/en/about-qbe">
                            <img src={QbeLogo} width="122" height="35" alt="QBE"/>
                          </a>
                      </div>
                  </div>

                <div className="main-content-container-boxed">
                    <div className="main-content-container-box">
                        <div className="main-content-title">{
                            cfg('labels.payment')
                        }</div>
                        <div className="main-content-box">

                            <div className="pricing-row no-topMargin">
                                <div className="pricing-block">
                                    <div className="highlight-text alignCenter">{cfg('labels.totalAmount')}</div>
                                    <div className="main-highlight-text alignCenter">
                                        <div>{_formatPaymentAmount(props.policyPayment.country, totalAmount)}</div>
                                    </div>
                                </div>
                            </div>
                            <div id="embed-target" className="paymentContainer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        policyPayment: _getPolicyPayment(state),
        country: _getCountry(state),
        lang: _getLang(state),
        paymentToken: _getPaymentToken(state)
    }
};

const mapDispatchToProps = {
    setLang: _setLang,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    resetPolicy: _resetPolicy,
    storePolicyPayment: _storePolicyPayment,
    failPayment: _onlineFailPayment,
    acceptPayment: _onlineAcceptPayment,
    initiateMastercardPayment:_initiateOnlineMastercardPayment,
    storePaymentToken: _storePaymentToken
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnlinePaymentProcessMPGS));

