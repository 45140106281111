import React, {useEffect, useState} from 'react';
import {Route} from 'react-router-dom';
import {createBrowserHistory} from 'history';

import {connect} from "react-redux";
import LoginPage from "../pages/login-page";
import PolicyPage from "../pages/policy-page";
import RenewalDetailPage from "../pages/renewal-detail-page";
import RenewalPaymentFinishPage from "../pages/renewal-payment-finish-page";
import AdhocDetailPage from "../pages/adhoc-detail-page";
import AdhocPaymentPage from "../pages/adhoc-payment-page";
import AdhocPaymentFinishPage from "../pages/adhoc-payment-finish-page";
import PayLaterPage from "../pages/pay-later-page";
import PayLaterFinishPage from "../pages/pay-later-finish-page";
import RenewalPage from "../pages/renewal-page";
import PaymentPage from "../pages/payment-page";
import FindMyPolicyPage from "../pages/find-my-policy-page";
import RegistrationPage from "../pages/registration-page";
import ProfilePage from "../pages/profile-page";
import PortfolioPage from "../pages/portfolio-page";
import PolicyStatusPage from "../pages/policy-status-page";
import {_getCountry, _getLang, _getPolicyToken, _getPortfolioToken, _getSingpassScript, _setCountry, _setLang, _checkSystemAvailable} from "../store/system/service";
import SecureRoute from "../utils/secure-route";
import BrokenPage from "../pages/broken-page";
import NavigationPage from "../pages/navigation-page";
import PayLaterDetailPage from "../pages/pay-later-detail-page";
import RenewalNoticePage from "../pages/renewal-notice-page";
import ResetPasswordPage from "../pages/reset-password-page";
import LoginSgpPage from "../pages/login-sgp-page";
import AdminLoginPage from "../pages/admin-login-page";
import OnlinePaymentPage from "../pages/online-payment-page";
import OnlinePaymentDetailPage from "../pages/online-payment-detail-page";
import OnlinePaymentProcessPage from "../pages/online-payment-process.page";
import OnlinePaymentFinishPage from "../pages/online-payment-finish-page";
import AutoRenewalManagePage from "../pages/auto-renewal-manage-page";
import UpdateCreditCardDetails from "../components/auto-renewal/auto-renewal-update-credit-card";
import AutoRenewalActivatePage from "../pages/auto-renewal-activate-page";
import AutoRenewalCancelPage from "../pages/auto-renewal-cancel-page";
import OnlinePaymentProcessMpgsPage from "../pages/mpgs/online-payment-process-mpgs-page";

let history;

export function getHistory() {
    if (!history) {
        history = createBrowserHistory();
    }

    return history;
}

const Routes = (props) => {
    const [country, setCountry] = useState("");
    const [scriptLoaded, setScriptLoaded] = useState(false);

    const initializeLanguage = () => {
        let url = window.location.href;
        if (url) {
            if (url.indexOf("/sg") !== -1 || url.indexOf("sg-") !== -1 || url.indexOf("#sg") !== -1) {
                if (!props.lang) {
                    props.setLang('en');
                }
                props.setCountry('SGP');
                setCountry('SGP');
                if (!scriptLoaded) {
                    loadSingpassScript();
                }
            }
            else if (url.indexOf("/hk") !== -1 || url.indexOf("hk-") !== -1 || url.indexOf("#hk") !== -1) {
                if (url.indexOf('?') !== -1) {
                    let params = new URLSearchParams(url.split('?')[1]);
                    let paramLanguage = params.get('lang');

                    // ONLY FOR HK URL: only set param language if "lang" exists in the URL params. Else set to chinese
                    if (paramLanguage) {
                        props.setLang(paramLanguage);
                    } else {
                        props.setLang('zh');
                    }

                } else {
                    props.setLang('zh');
                }

                props.setCountry('HKG');
                setCountry('HKG');
            }else if(url.indexOf("/my") !== -1 || url.indexOf("my-") !== -1 || url.indexOf("#my") !== -1){
                props.setLang('en');
                props.setCountry('MYS');
                setCountry('MYS');
            } else {
                props.setLang('en');
                props.setCountry('HKG');
                setCountry('HKG');
            }
        }
    }

    const loadSingpassScript = () => {
        props.getSingpassScript().then(
            resp => {
                console.log('singpass script =' + resp);
                const script = document.createElement("script");
                script.src = resp;
                script.async = true;
                document.body.appendChild(script);
                setScriptLoaded(true);
            }, (error) => {
                console.error('loadSingpassScript has failed', error);
            }
        )
    }

    useEffect(() => {
        props.checkSystemAvailable(props.country);
    }, []);


    const isPolicyAuthenticated = () => {
        let status = false;
        if (props.portfolioToken) {
            // const decoded = jwt(props.policyToken);
            // const expiryDate = new Date( decoded.exp * 1000);
            // status =  expiryDate > new Date();
            status = true;
        }
        // console.log("authenticated = " + status);
        return status;
    }

    const hasPortfolioAccessToken = () => {
        let status = false;
        if (props.portfolioToken){
            status = true;
        }

        // console.log("authenticated = " + status);
        return status;
    }

    // added this to fix  delayed translation issue on QnectTextField Items
    if (!props.lang) {
        setTimeout(() => initializeLanguage());
    }

    const getLoginPage = () => {
        if(country === 'HKG' || country === 'MYS'){
            return LoginPage
        }
        return LoginSgpPage
    }

    return (
        <>
            <Route path='/' exact component={getLoginPage()} />
            {/*<Route path='/' exact component={LoginPage} />*/}
            <Route path='/admin-login' exact component={AdminLoginPage} />
            <Route path='/login' exact component={getLoginPage()} />
            <Route path='/login/:section' exact component={LoginPage} />
            <SecureRoute path='/policy'  component={PolicyPage} auth={isPolicyAuthenticated()}/>
            <Route path='/policy-status' exact component={PolicyStatusPage} />
            <Route path='/renew/detail/:token/:iv/:lang' exact component={RenewalPage} />
            <Route path='/renewal-notice' exact component={RenewalNoticePage} />
            <Route path='/renew/renewal-detail' exact component={RenewalDetailPage} />
            <Route path='/renew/pay-later/:token/:iv/:lang' exact component={PayLaterPage} />
            <Route path='/renew/pay-later-detail' exact component={PayLaterDetailPage} />
            <Route path='/renew/pay-later-finish' exact component={PayLaterFinishPage} />
            <Route path='/renewal-payment-finish' component={RenewalPaymentFinishPage} />
            <Route path='/payment' exact component={PaymentPage} />
            <Route path='/payment-detail' exact component={AdhocDetailPage} />
            <Route path='/payment-process' exact component={AdhocPaymentPage} />
            <Route path='/payment-finish' exact component={AdhocPaymentFinishPage} />
            <Route path='/online-payment' exact component={OnlinePaymentPage} />
            <Route path='/online-payment-detail' exact component={OnlinePaymentDetailPage} />
            <Route path='/online-payment-process' exact component={OnlinePaymentProcessPage} />
            <Route path='/online-payment-process-mpgs' exact component={OnlinePaymentProcessMpgsPage} />
            <Route path='/online-payment-finish' exact component={OnlinePaymentFinishPage} />

            <SecureRoute path='/manage-auto-renewal' component={AutoRenewalManagePage} auth={isPolicyAuthenticated()}/>
            <SecureRoute path='/auto-renewal/manage-auto-renewal/:operation?' component={AutoRenewalManagePage} auth={isPolicyAuthenticated()}/>
            <SecureRoute path='/auto-renewal/update-credit-card' component={UpdateCreditCardDetails} auth={isPolicyAuthenticated()}/>
            <SecureRoute path='/auto-renewal/activate' component={AutoRenewalActivatePage} auth={isPolicyAuthenticated()}/>
            <SecureRoute path='/auto-renewal/cancel' component={AutoRenewalCancelPage} auth={isPolicyAuthenticated()}/>

            <Route path="/find-my-policy/:role/:section" component={FindMyPolicyPage} />
            <Route path="/registration/:section" component={RegistrationPage} />
            <SecureRoute path='/profile' component={ProfilePage} auth={hasPortfolioAccessToken()}/>
            <SecureRoute path='/portfolio' component={PortfolioPage} auth={hasPortfolioAccessToken()}/>
            <Route path='/pageUnavailable' exact component={BrokenPage}/>
            <Route path='/resetPassword' exact component={ResetPasswordPage}/>
            <Route path='/navigate' exact component={NavigationPage} />
        </>
    );
}


const mapStateToProps = (state) => {
  return {
    country: _getCountry(state),
    lang: _getLang(state),
    policyToken: _getPolicyToken(state),
    portfolioToken: _getPortfolioToken(state)
  }
}

const mapDispatchToProps = {
    setLang: _setLang,
    setCountry: _setCountry,
    getSingpassScript: _getSingpassScript,
    checkSystemAvailable: _checkSystemAvailable
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
